body {
    margin: 0 !important;
    padding-bottom: 48px;
    overflow-y: hidden;
}

.defaultButton {
    color: black !important;
    border-color: black !important;
}

.preSelectIcon {
    margin: 16px 16px 16px 0;
}


.p-dropdown:not(.p-disabled):hover {
    border-color: #afc928;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 0.2rem rgba(175, 201, 40, 0.22);
}

.p-contextmenu {
    border-radius: 6px!important;
}

.p-menuitem-link {
    padding: 8px 12px!important;
}

.fancyScroll::-webkit-scrollbar {
    width: 12px;
}

.fancyScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.fancyScroll::-webkit-scrollbar-thumb {
    background: #b2b2b2;
    border-radius: 8px;
    margin-left: 2px;
    margin-right: 2px;
}

.fancyScroll::-webkit-scrollbar-thumb:hover {
    background: #7e7e7e;
}

/* Healthchecks specific */
.HWarning {
    background-color: #ffcdd2 !important;
    color: #ba000d!important;
}

.HWarning:hover {
    background-color: #dab1b5 !important;
}