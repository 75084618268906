.packagingAccordion {
    border: 1px solid inherit;
    margin-bottom: 8px;
}

.packagingAccordion:first-of-type {
    border-radius: 6px 6px 0 0;
}

.packagingAccordion:last-of-type {
    border-radius: 0 0 6px 6px;
}

.packagingAccordion::before {
    display: none !important;
}